import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import {
  MIGRATION_STATUS_LABELS,
  MIGRATION_STATUS_COLORS,
} from '../../config/const.config';

const MigrationStatusLabel = ({ value }) => {
  const label = MIGRATION_STATUS_LABELS[value] || 'Unknown';
  const color = MIGRATION_STATUS_COLORS[value] || 'primary';

  return (
    <Chip
      label={label}
      color={color}
      variant="filled"
      size="small"
      sx={{ fontWeight: 700 }}
    />
  );
};

MigrationStatusLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default MigrationStatusLabel;
