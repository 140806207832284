import React from 'react';
import AppLayout from '../../layout/app.layout';
import ActiveSubscribers from '../../components/reports/active-subscribers.component';

const ActiveSubscribersView = () => {
  const pageTitle = 'Reports / Active Subscribers';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ActiveSubscribers />
    </AppLayout>
  );
};

export default ActiveSubscribersView;
