import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Button,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { LISTING_COLUMNS } from '../../config/module-configs/realtime-live-users.config';
import Iconify from '../common/iconify.component';
import Sortable from '../table-elements/sortable.component';
import NoData from '../table-elements/no-data.component';
import Error from '../table-elements/error.component';
import Loading from '../table-elements/loading.component';
import CellValue from '../table-elements/cell-value.component';
import { getRealtimeDashboard } from '../../services/reports.service';

const ListRealtimeLiveUsers = () => {
  const columns = LISTING_COLUMNS;
  const colSpan = columns.length;

  const [options, setOptions] = useState({
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    types: [],
    error: false,
    loading: true,
  });
  const [autoLoadCounter, setAutoLoadCounter] = useState(1);
  const refreshInterval = useRef(null);

  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleAutoRefreshData = () => {
    setAutoLoadCounter(autoLoadCounter + 1);
  };
  const handleTypeChange = (event) => {
    const { value } = event.target;
    setOptions({
      ...options,
      types: value !== 'all' ? [value] : [],
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const clearAutoRefreshInterval = () => {
    clearInterval(refreshInterval.current);
  };
  const setAutoRefreshInterval = () => {
    clearAutoRefreshInterval();
    refreshInterval.current = setTimeout(() => {
      handleAutoRefreshData();
    }, 5000);
  };

  useEffect(() => {
    const payload = {
      types: options.types,
    };
    if (options.sortBy && options.sortOrder) {
      payload.sortBy = options.sortBy;
      payload.sortOrder = options.sortOrder;
    }
    getRealtimeDashboard(payload)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          rows: res?.data?.rows || [],
          error: false,
        });

        setAutoRefreshInterval();
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          rows: [],
          error: true,
        });
      });

    return () => {
      clearAutoRefreshInterval();
    };
  }, [options.reloadCounter, autoLoadCounter]);

  return (
    <>
      <Stack direction="row" justifyContent="right" sx={{ px: 1, py: 2 }}>
        <FormControl size="small">
          <InputLabel id="watch-type-label">Watch Type</InputLabel>
          <Select
            value={options.types[0] || 'all'}
            onChange={handleTypeChange}
            labelId="watch-type-label"
            label="Watch Type"
            size="small"
            sx={{ width: 150, marginRight: 2 }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="AVOD">AVOD</MenuItem>
            <MenuItem value="SVOD">SVOD</MenuItem>
            <MenuItem value="TVOD">TVOD</MenuItem>
          </Select>
        </FormControl>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
          disabled={options.loading}
        >
          Refresh
        </Button>
      </Stack>

      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((c) => (
                  <TableCell
                    key={`col-${c.id}`}
                    align={c.align}
                    width={c.width}
                    sx={{
                      cursor: c.sortable ? 'pointer' : 'inherit',
                      padding: '5px 10px !important',
                      fontSize: '0.750rem !important',
                    }}
                    onClick={() => {
                      if (c.sortable) {
                        if (c.dataKey === options.sortBy) {
                          if (options.sortOrder === '') {
                            handleSortingChange(c.dataKey, 'asc');
                          } else if (options.sortOrder === 'asc') {
                            handleSortingChange(c.dataKey, 'desc');
                          } else if (options.sortOrder === 'desc') {
                            handleSortingChange('', '');
                          }
                        } else {
                          handleSortingChange(c.dataKey, 'asc');
                        }
                      }
                    }}
                  >
                    <Stack direction="row" justifyContent={c.align}>
                      {c.sortable && (
                        <Sortable
                          dataKey={c.dataKey}
                          sortBy={options.sortBy}
                          sortOrder={options.sortOrder}
                        />
                      )}
                      {c.label}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {!options.loading && !options.error && (
                <>
                  {options.rows.map((r, index) => (
                    <TableRow key={`row-${r[columns[0].dataKey]}`}>
                      {columns.map((c) => (
                        <TableCell
                          key={`data-col-${c.id}-${r[c.dataKey]}`}
                          align={c.align}
                          width={c.width}
                          sx={{
                            padding: '5px 10px !important',
                            fontSize: '0.750rem !important',
                          }}
                          variant={index === 0 ? 'head' : 'string'}
                        >
                          <CellValue type={c.type} value={r[c.dataKey]} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              )}

              {!options.loading &&
                !options.error &&
                options.rows.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={colSpan}>
                      <NoData />
                    </TableCell>
                  </TableRow>
                )}

              {!options.loading &&
                options.error &&
                options.rows.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={colSpan}>
                      <Error />
                    </TableCell>
                  </TableRow>
                )}

              {options.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={colSpan}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default ListRealtimeLiveUsers;
