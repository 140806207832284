import React from 'react';
import AppLayout from '../../layout/app.layout';
import UserCounts from '../../components/reports/user-counts.component';

const UsersCountOverviewView = () => {
  const pageTitle = 'Reports / Users Count';

  return (
    <AppLayout pageTitle={pageTitle}>
      <UserCounts />
    </AppLayout>
  );
};

export default UsersCountOverviewView;
